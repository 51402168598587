/* eslint-disable no-nested-ternary */
import { bindActionCreators } from 'redux'
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import { Link, withRouter } from 'react-router-dom'
import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import { Card, Header, Main } from 'generics/Card'
import { EnergyMap } from 'generics/Charts'
import { getCSSFromSVGs, name } from 'helpers'
import { getEntityByContextRoute, MY_5_DYNAMICS_PROFILE } from 'constants/urls'
import { ACTIONS, I18N, PERSON } from 'constants/props'
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes'
import { StyledButton } from 'generics/StyledFormComponents'
import * as proMemberActions from 'app_modules/proMember/actions'
import * as accountSelectors from 'app_modules/accounts/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import Avatar from 'generics/Avatar'
import PDFEnergyMaps from 'generics/PDFEnergyMaps'
import TestingScores from 'generics/TestingScores'
import Translation from 'generics/Translation'

import styles from './WelcomeBack.scss'

const EDUCATION_ACCOUNT_TYPE = 'education'

const WelcomeBack = ({
  className,
  i18n,
  isLearningAccount,
  isProMember,
  overview,
  profile,
  proMemberActionsProps,
  isLimitedMember,
}) => {
  const [isFetching, setIsFetching] = useState(false)

  const energyMaps = useRef(null)

  const grantAccess = isProMember || !isLimitedMember

  const handleDownloadOverview = () => {
    const { accountId } = profile
    const { svgs } = energyMaps.current
    const { id, svg } = svgs[0]
    const css = getCSSFromSVGs(svgs)

    setIsFetching(true)

    const onComplete = () => setIsFetching(false)

    api.files.downloadOverviewPDF(
      {
        accountId,
        css,
        isLearningAccount,
        profile: {
          fullName: name.call(profile, '_'),
          id,
          svg,
        },
      },
      onComplete,
      onComplete,
    )
  }

  const handleDownloadImage = () => {
    const { accountId } = profile
    const { svgs } = energyMaps.current
    const { id, svg } = svgs[0]
    const css = getCSSFromSVGs(svgs)
    setIsFetching(true)

    const onComplete = () => setIsFetching(false)

    api.files.downloadEnergyMap(
      {
        accountId,
        css,
        profile: {
          fullName: name.call(profile, '_'),
          id,
          svg,
        },
      },
      onComplete,
      onComplete,
    )
  }

  const {
    account: { accountType, enableSensai },
    accountId,
    firstName,
  } = profile
  const myProfileRoute = formatRoute(MY_5_DYNAMICS_PROFILE, { accountId })
  const category =
    accountType === EDUCATION_ACCOUNT_TYPE
      ? 'education_5'
      : accountType === LEARNING_ACCOUNT_TYPE
      ? 'learning_5'
      : 'fundamentals_1'
  const moduleKey =
    accountType === EDUCATION_ACCOUNT_TYPE
      ? 'working_styles_graph_18'
      : accountType === LEARNING_ACCOUNT_TYPE
      ? 'learning_report_16'
      : 'work_preferences_1'

  const workPreferencesRoute = getEntityByContextRoute({
    accountId,
    category,
    contextType: 'individual',
    menuItem: 'my-5-dynamics',
    entity: profile,
    moduleKey,
  })

  return (
    <Card
      barClassName={styles.card__bar}
      className={classnames(styles.card, className)}
      data-test-component="welcome-back"
      isFetching={!overview || isFetching}
    >
      <Header ariaLabel={sprintf(i18n.pagePersonalDashboard.welcomeBack.title, firstName)} className={styles.header}>
        <Container>
          <Row>
            <Col xs="12" md="2">
              <Avatar className={styles['avatar-user']} default icon={{ name: 'photo' }} profile={profile} />
            </Col>

            <Col xs="12" md="10">
              <h2 className={styles.welcome}>{sprintf(i18n.pagePersonalDashboard.welcomeBack.title, firstName)}</h2>
              <Link
                className={classnames(styles.link, styles['edit-profile'])}
                href={myProfileRoute}
                title={i18n.pagePersonalDashboard.welcomeBack.editProfile}
                to={myProfileRoute}
                id="main"
              >
                {i18n.pagePersonalDashboard.welcomeBack.editProfile}
              </Link>
            </Col>
          </Row>
        </Container>
      </Header>

      <Main>
        <Container>
          <Row>
            <Col xs="12" md={enableSensai ? '12' : '7'} className={enableSensai ? styles['overview-col'] : ''}>
              <h2 className={styles.overview}>
                {i18n.pagePersonalDashboard.welcomeBack.overview}
                <TestingScores profile={profile} />
              </h2>

              <div
                className={classnames('epic', styles.content)}
                /* eslint-disable react/no-danger */
                dangerouslySetInnerHTML={{ __html: overview }}
                /* eslint-disable react/no-danger */
              />

              {grantAccess ? (
                <Link
                  className={classnames(styles.link, styles['read-more'])}
                  href={workPreferencesRoute}
                  title={i18n.pagePersonalDashboard.welcomeBack.readMore}
                  to={workPreferencesRoute}
                >
                  {i18n.pagePersonalDashboard.welcomeBack.readMore}
                </Link>
              ) : (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  className={classnames(styles.link, styles['read-more'])}
                  title={i18n.pagePersonalDashboard.welcomeBack.readMore}
                  onClick={() => proMemberActionsProps?.toggleProMemberModal()}
                >
                  {i18n.pagePersonalDashboard.welcomeBack.upgrade}
                </Link>
              )}
            </Col>

            <Col
              xs="12"
              md={enableSensai ? '12' : '5'}
              className={classnames(styles.col, enableSensai ? styles['chart-col'] : '')}
            >
              <Row>
                <Col
                  xs="12"
                  md={enableSensai ? '6' : '12'}
                  className={classnames(styles.col, {
                    [styles['energy-col']]: enableSensai,
                  })}
                >
                  <div className={styles.chart}>
                    <EnergyMap className={styles.chart__graph} data={profile} />
                  </div>
                </Col>

                <Col
                  xs="12"
                  md={enableSensai ? '6' : '12'}
                  className={classnames(styles.col, {
                    [styles['button-col']]: enableSensai,
                    [styles.hidden]: !enableSensai,
                  })}
                >
                  <div className={styles['button-container']}>
                    <StyledButton
                      aria-label={`${i18n.pagePersonalDashboard.welcomeBack.energyMap} download`}
                      className={styles['download-link']}
                      onClick={grantAccess ? handleDownloadImage : () => proMemberActionsProps?.toggleProMemberModal()}
                      color="primary"
                    >
                      {i18n.pagePersonalDashboard.welcomeBack.energyMap}
                    </StyledButton>
                    <StyledButton
                      aria-label={
                        isLearningAccount
                          ? `${i18n.pagePersonalDashboard.welcomeBack.learningReport} download`
                          : `${i18n.pagePersonalDashboard.welcomeBack.overviewReport} download`
                      }
                      className={styles['download-link']}
                      onClick={
                        grantAccess ? handleDownloadOverview : () => proMemberActionsProps?.toggleProMemberModal()
                      }
                      color="primary"
                    >
                      {isLearningAccount
                        ? i18n.pagePersonalDashboard.welcomeBack.learningReport
                        : i18n.pagePersonalDashboard.welcomeBack.overviewReport}
                    </StyledButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <PDFEnergyMaps
          innerRef={nodeElement => {
            energyMaps.current = nodeElement
          }}
          profiles={[profile]}
        />
      </Main>
    </Card>
  )
}

WelcomeBack.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  isLearningAccount: PropTypes.bool,
  isProMember: PropTypes.bool.isRequired,
  overview: PropTypes.string.isRequired,
  profile: PERSON.isRequired,
  proMemberActionsProps: ACTIONS.isRequired,
  isLimitedMember: PropTypes.bool.isRequired,
}

WelcomeBack.defaultProps = {
  className: null,
  isLearningAccount: false,
}

const mapStateToProps = state => ({
  isProMember: accountSelectors.isProMember(state),
  overview: sessionSelectors.overviewSummary(state),
  profile: sessionSelectors.currentProfile(state),
})

const mapDispatchToProps = dispatch => ({
  proMemberActionsProps: bindActionCreators(proMemberActions, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Translation(WelcomeBack, ['pagePersonalDashboard'])),
)
